.home-ultima-actualizacíon {

}

.home-ultima-actualizacíon-icon {
    background-color: #f3f3f3;
}

.home-ultima-actualizacíon-icon:hover {
    background-color: #f3f3f3;
}

.home-info {
    text-align: left;
    background-color: white;
}

.home-info>div{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: stretch;
}

.home-info-text {
    margin-left: 10px;
    font-weight: 300;
    font-size: 1em;
}

.ant-card {
    box-shadow: none;
}

.add-marketplace-button {
    margin-top: 15px;
}

.intro-card .ant-card-body{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
}

.filter-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    margin-top:5px;
    -moz-box-shadow: 0 0 0.2em 0.2px #ededed;
    -webkit-box-shadow: 0 0 0.2em 0.2px #ededed;
    margin-bottom: 10px;
}

#formulario .ant-divider{
    margin-top:0;
}

#formulario .ant-divider a {
    font-size: .8em;
    font-weight: 300;
    color: #04dfa0;
}

.ant-card.ant-card-bordered.filtros-meses{
    margin-top:5px;
    -moz-box-shadow: 0 0 0.2em 0.2px #ededed;
  -webkit-box-shadow: 0 0 0.2em 0.2px #ededed;
  margin-bottom: 5px;
}

.ant-card.ant-card-bordered.filtros-meses .ant-card-body{
    padding:12px;
}

.ant-card.ant-card-bordered.filtros-meses .ant-card-body .ant-row{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.anticon svg {
    display: inline-block;
    fill: #04dfa0;
}

.intro-totales .ant-col.ant-col-8,.intro-totales-detalle .ant-col.ant-col-8{
    margin-top:5px;
}

.intro-totales .ant-card-body, .intro-totales-detalle .ant-card-body{
    padding:12px;
}

.intro-totales h4, .titulo h4, .bot-3-cards h4,.intro-totales-detalle h4{
    color: #010c33;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1;
    text-align: left;
}

.intro-totales h1{
    margin-top: 0.7em !important;
    font-style: normal;
    color: #010c33;
}

/* .ant-card.ant-card-bordered.filtros-meses .ant-card-body .ant-row button:hover{
    background-color: #00E9A0;
    color: #f3f3f3 !important;
} */

.hide{
    display:none;
    visibility: hidden;
    opacity: 0;
}

.user-actions{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    align-content: stretch;
    width: 50%;
    font-weight: 300;
    font-size: 1em;
}

.ant-row.user-messges {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.cardTableDashboard .ant-table-tbody > tr > td,
.cardTableDashboard .ant-table-tbody > tr > th,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td{
    padding:5px;
    overflowX:hidden;
}

.cardTableDashboard .ant-table-container table > thead > tr:first-child th:first-child,
.cardTableDashboard .ant-table-container table > tbody > tr> td:first-child,
.cardTableDashboard .ant-table-container table > thead > tr:first-child th:last-child{
    padding:5px;
    text-align: center;
}

.cardTableDashboard .ant-table-container table > tbody > tr> td:last-child{
    text-align: right;
}

.bot-3-cards{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
}

.bot-3-cards canvas{
    min-height:150px;
}

.detalles-home-listing-card{
    margin:40px 0px 0px 24px;
}

.intro-totales-detalle{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 210px;
    width: 100%;
}

.intro-totales-detalle canvas{
    min-height:150px;
}

.detalle-table table{
    table-layout: auto !important;
    width:100% !important;
}

.detalle-table .ant-table-body{
    /*overflow: hidden !important;*/
}

.detalle-table table>thead>tr>th:first-child,.detalle-table table>tbody>tr>td:first-child{
    width:40%;
}

.detalle-table table>thead>tr>th:last-child{
    display: none;
}

.detalle-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.detalle-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td{
    border-right: 0;
}

.intro-totales-detalle>div:last-child{
    width:24%;
}